import React, { useContext, useState, useEffect } from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import { FaEye, FaEyeSlash, FaUserAlt } from "react-icons/fa";
import "./login.css";
export default function Login() {
    const navigate = useNavigate();
    const userVal = useContext(UserContext)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const hasTokenCookie = document.cookie.split(';').some((cookie) => cookie.trim().startsWith('token='));
	
	 const [show, setShow] = useState(false);

  const handlepassword = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

    if (hasTokenCookie) {
        console.log('User has the token cookie.');
        //   userVal.setIslogged(true);
    } else {
        // userVal.setIslogged(false);
        console.log('User does not have the token cookie.');
    }
    function LoginUser(e) {
        e.preventDefault();
        let data = { 'username': username, "password": password }
        axios.post(`/login`, data)
            .then((res) => {
                const type = res.data[0].type
                if (res.data) {
                    alert("Login Successfully!");
                    localStorage.setItem("user", "76342391251#@%#^%*(^%&^$$%#@$%448941/*-++y$#%$^^&^*$@");
                    localStorage.setItem("usertype", type);
                    navigate('/backend');
                } else {
                    localStorage.removeItem("admin");
                    alert("Unexpected response from the server");
                }
            })
            .catch((error) => {
                alert("Error during login. Please try again.");
            });
    }
    function Backtohome() {
        localStorage.removeItem("admin");
        localStorage.removeItem("user");
        window.location.href = "/";
    }

    useEffect(() => {
        const id = "%@#^#%&^*%^&$%@@^@%$%^,^$#@@#8757636345^$%^$%&32422"
        localStorage.setItem("admin", id)
    }, [])

    return (
        <>
			<div style={{height:"70px"}}></div>
            <div className="container-fluid main-login-con ">
        <div className="container ">
          <div className=" row d-xl-flex gap-4 justify-content-center align-items-center ">
            <div
              className=" login-left col-lg-3  d-flex   flex-column gap-4  justify-content-center align-items-center "
              data-tilt
            >
              <img
                src="/images/loginicon.webp"
                alt="IMG"
                style={{ maxWidth: "245px" }}
              />
              <button type="submit" className=" login-back-to-home" onClick={Backtohome}>
                Back To Home
              </button>
            </div>

            <form className="login-form col-lg-4 ">
              <span className="login-form-title">
                <h1 className="text-center text-white"> Member Login</h1>
              </span>

              {/* <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz"> */}
              <div className="login-name-con">
                <FaUserAlt className="name-icon" />

                <input
                  className="login-input"
                  type="text"
                  name="email"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {/* </div> */}

              {/* <div className="wrap-input100 validate-input" data-validate="Password is required"> */}

              <div className="login-password-con ">
                {show ? (
                  <FaEye className="password-icon " onClick={handlepassword} />
                ) : (
                  <FaEyeSlash
                    className="password-icon "
                    onClick={handlepassword}
                  />
                )}
                <input
                  className="input100 login-input password-input "
                  type={show ? "text" : "password"}
                  name="pass"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {/* </div> */}

              <div className="login-btn-con">
                <button 
                className="login-btn-upper" 
                onClick={LoginUser}
                >
                  
                  Login
                  
                </button>

                <div className="text-center mt-3 forget-username-password ">
                  <span className="forget-login me-1">Forgot</span>
                  <a className="txt2" href="#">
                    Username/Password?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> 

        </>
    );
}

