import React, { useState, useEffect, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPaginate from "react-paginate";
import "../../styles/main/sale.css";
import "../../styles/main/main.css"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SearchContext from "../../Context/Context1";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';


const ITEMS_PER_PAGE = 12;
const SaleDubaipro = () => {
	 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getValues = useContext(SearchContext)
  const [datalength, setdatalength] = useState();
  const [tata, settata] = useState([]);
  const [jsonData, setJsonData] = useState([]);


 

  const navigate = useNavigate()

  const [dropvalue, setDropvalue] = useState('Show All');
  const [currentPage, setCurrentPage] = useState(0);
 
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const name = "rentpagedb"
    const rent = {"tablename" : "buypagedb"};
          axios.post(`/staticdata` , rent).then((res) => {
          setdata(res.data);
    })
      .catch((error) => {
        console.error("Error fetching data from the server:", error);
      });
  }, []);

	{/*function Singleproperty(e) {
    const id = e.target.id

    localStorage.setItem('propertyid', edd)
    navigate('/singleproperty')
  }*/}

  useEffect(() => {
    const name = "Sale"
    axios.get("/allprosale").then((res) => {
		//console.log(res.data);
      setdatalength(res.data.length);
     
      settata(res.data);
      setLoading(false);
    })
      .catch((error) => {
        console.error("Error fetching data from the server:", error);
      });
  }, []);


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (tata) {
        const val = tata.filter((tata) => tata.Ad_Type.includes("Sale"));
        setJsonData(val);
      }
      if (getValues.isSearch && tata && getValues.loc) {
        const searchTerm = getValues.loc;
        const regex = new RegExp(searchTerm, "i"); // Create a case-insensitive regex
        const val = tata.filter((item) => regex.test(item.Community) || regex.test(item.Property_Name));
        setdatalength(val.length)
        setFilteredData(val);
      }
      setLoading(false);; // Set loading to false when data is available
    }, 1000);
  }, [tata, getValues.isSearch, getValues.loc,dropvalue]);


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
	

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [DynamicbtnStyle, setDynamicbtnStyle] = useState({
    backgroundColor: 'transparent',
    Color: '#212529',
    Border: '1px solid rgb(201, 199, 199)'
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const ToggleDropdown = (id) => {
    setIsDropdownOpen((prevId) => (prevId === id ? null : id));
  }


  const handleButtonClick = () => {


    setDynamicbtnStyle({
      backgroundColor: 'transparent',
      Color: '#212529',
      Border: '1px solid rgb(201, 199, 199)'
    });

  };
  function funmini() {
    let c = document.getElementById("allBtn")
    c.classList.toggle("mini")

  }



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const searchdata = jsonData.filter(item =>
      item.Property_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.Property_Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(searchdata);
    setdatalength(searchdata.length);
  };
	
	
 /* function Singleproperty(e) {
    const id = e.target.id
    localStorage.setItem('propertyid', id)
    navigate('/singleproperty')
  } */

  const priceList = ["750000", "1000000", "2000000", "3000000", "4000000", "5000000", "6000000", "7000000", "8000000", "9000000", "10000000", "20000000", "30000000", "40000000", "50000000", "60000000", "70000000", "80000000"]


  //Price Filteration 


  const handleMinPriceSelect = (e) => {

    setLoading(true);
    // setMinPrice(value);
    const min =e.target.value;

    setTimeout(() => {
      let filteredData;
      if (min === "Show All") {
        filteredData = jsonData;
      } else {
     
        filteredData = jsonData.filter(item => item.Price >= parseFloat(min));
      
      }
  
      // Update state with filtered data
      setdatalength(filteredData.length);
      setFilteredData(filteredData);
  
      // Set loading state to false after filtering is done
      setLoading(false);
  
   
    }, 1000); // Adjust the delay time as needed (in milliseconds)
  };
  const handleMaxPriceSelect = (e) => {

    setLoading(true);
    // setMinPrice(value);
    const max =e.target.value;
   
    setTimeout(() => {
      let filteredData;
      if (max === "Show All") {
        filteredData = jsonData;
      } else {
     
        filteredData = jsonData.filter(item => item.Price <= parseFloat(max));
       
      }
  
      // Update state with filtered data
      setdatalength(filteredData.length);
      setFilteredData(filteredData);
  
      // Set loading state to false after filtering is done
      setLoading(false);
  
     
    }, 1000); // Adjust the delay time as needed (in milliseconds)
  };
 
  const handleDropdownItemClick = (value) => {
   
    setDropvalue(value.target.value);
   
  };
  
 
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (tata) {
        const val = tata.filter((tata) => tata.Ad_Type.includes("Sale"));
        
        setJsonData(val);
      }
     if (getValues.isSearch && tata && getValues.loc) {
  const searchTerm = getValues.loc;
  console.log(searchTerm, "search location");
  // Escape special characters in the search term and create a case-insensitive regex
  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedTerm, "i"); 

  const val = tata.filter((item) => 
    regex.test(`${item.Property_Name}, ${item.Emirate}, ${item.Community}`)
  );

  setdatalength(val.length)
  setJsonData(val);
}


      setLoading(false); // Set loading to false when data is available
    }, 1000);

  }, [tata, getValues.isSearch, getValues.loc]);

  const pageCount = Math.ceil(datalength / ITEMS_PER_PAGE);



  function handleSixPlusBed() {
    setDropvalue("6+");
  }


  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      let newData = [];
    
      if (dropvalue === "Show All") {
        
        newData = jsonData;
      }
      else if (dropvalue === "Villa") {
        newData = jsonData.filter((item) => item.Unit_Type === "Villa");
      } else if (dropvalue === "Apartment") {
        newData = jsonData.filter((item) => item.Unit_Type === "Apartment");
      } else if (dropvalue === "Townhouse") {
        newData = jsonData.filter((item) => item.Unit_Type === "Townhouse");
      } else if (dropvalue === "Penthouse") {
        newData = jsonData.filter((item) => item.Unit_Type === "Penthouse");
      
      } else if (dropvalue === "Duplex") {
        newData = jsonData.filter((item) => item.Unit_Type === "Duplex");
      
      } else if (dropvalue === "Land") {
        newData = jsonData.filter((item) => item.Unit_Type === "Land");
      
      } else if (dropvalue === "Plot") {
        newData = jsonData.filter((item) => item.Unit_Type === "Plot");
      }
      else if (dropvalue === "6+") {
        newData = jsonData.filter((item) => item.Bedrooms >= 6);
      }

      else {
        // Assuming dropvalue represents the selected number of bedrooms
        const selectedBedrooms = parseInt(dropvalue);
        newData = jsonData.filter((item) => {
          // Filter items with the selected number of bedrooms
          return parseInt(item.Bedrooms, 10) === selectedBedrooms;
        });
      
      }
      setFilteredData(newData);
      setdatalength(newData.length)
      setLoading(false); // Set loading to false when the search is completed
    }, 1000); // Simulating 2 seconds loading delay

    return () => clearTimeout(delay);
  }, [dropvalue, jsonData]);
	
	
	 useEffect(() => {
    if (searchTerm === "") {
    

      setFilteredData(jsonData)
      setdatalength(jsonData.length)

    }
  }, [searchTerm])

	// =======================================================================================================================================
var addressData;
const uniquePropertyNames = new Set();
const uniqueJsonData = filteredData.filter(item => {
  const propertyName = item.Property_Name.toLowerCase();
  if (uniquePropertyNames.has(propertyName)) {
    return false;
  }
  uniquePropertyNames.add(propertyName);
  return true;
});
	
if (searchTerm.length) {
  addressData = uniqueJsonData.filter(item =>
    item.Property_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
	item.Emirate.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Community.toLowerCase().includes(searchTerm.toLowerCase())
  );
}
  const handleItemClick = (selectedItem) => {
    setSearchTerm(selectedItem);
	console.log(searchTerm, " ", selectedItem);
	const searchdata = jsonData.filter(item =>
      selectedItem.toLowerCase().includes(item.Property_Name.toLowerCase())
    );
	  console.log(searchdata);
    setFilteredData(searchdata);
    setdatalength(searchdata.length);
  };
// =======================================================================================================================================

 
  const displayedItems = filteredData.length > 0  ? (
    filteredData
      .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE)
      .map((item) => {
        const im = JSON.parse(item.Imagelink);
        const items = im[1];
     
        return (
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4 cardspadding"   key={item.PropertyID}>
            <div className="card" id="main-div-card" style={{ borderRadius: "0px", overflow:"hidden" }}>
	  <Link to={`/${item.pagelink}`}>
	  <div className="h-a-v-e-m-o-r-e"  > 
              <LazyLoadImage className="card-img-top" src={items} alt="Card cap" style={{ height: '210px', cursor: 'pointer', borderRadius: "0px" }} />
              <div className="card-body" style={{ padding: '20px' }}>
			              <h3 className="Property_Name">{item.Property_Name}</h3>
                <h5 className="card-title Cardtitle"> {item.Property_Title}</h5>
                <p className="card-text details" style={{ marginTop: '-5px', minHeight: '10px', overflow: 'hidden', color:"#4e5158" }}>
                  {item.Community}, {item.Unit_Type}.   
                </p>          
                  <div className="cards_new_alter">

                  <div style={{display:"flex",alignItems:"center",gap:3}}>
                  <LazyLoadImage className="cards_new_icon" src="/images/bedromm.png"  alt="bedroom" />
                  <span className="font_change_cards" style={{ color:"#4e5158"}}>{item.No_of_Rooms}</span>
                  <span id="" style={{color:"#ddd"}}>|</span>
                  </div>

                  <div  style={{display:"flex",alignItems:"center",gap:3}}>
                  <LazyLoadImage className="cards_new_icon" src="/images/bathromm.png" alt="bathroom" />
                  <span className="font_change_cards" style={{ color:"#4e5158"}}>{item.No_of_Bathroom}</span>
                  <span id="" style={{color:"#ddd"}}>|</span>
                  </div>

                  <div style={{display:"flex",alignItems:"center", gap:3}}>
                  <LazyLoadImage className="cards_new_icon" src="/images/Sizee.png" alt="bedroom" />
				  		<span className="font_change_cards" style={{ color:"#4e5158"}}>{item.Unit_Builtup_Area}</span>
                  <span className="text-lowercase" style={{color:"#4e5158"}}>{item.unit_measure}</span>
                    </div>

                </div>
                <p className="price-details">AED <strong className="price-all">{formatNumberWithCommas(item.Price)}</strong></p>
              </div>
			
			 </div>
			</Link> 
            </div>
          </div>
        );
      })
  ) :
 
      filteredData.length === 0 ? (
     
    <div style={{ justifyContent: "center" }}><h3>No Result Found!</h3></div>

  
      ) :
        (
          jsonData
            .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE)
            .map((item) => {
              const im = JSON.parse(item.Imagelink);
              const items = im[1];
          
              return (
                <div className="col-md-3 mb-12 cardspadding" key={item.PropertyID} >
                  <div onClick={Singleproperty} id={item.PropertyID} className="card" id="main-div-card" style={{overflow:"hidden"}}>
			         <Link to={`/${item.pagelink}`}>
                    <LazyLoadImage  className="card-img-top" src={items} alt="Card cap" style={{ height: '210px', cursor: 'pointer' }} />
                    <div className="card-body" style={{ padding: '20px' }}>
					            <h3 className="Property_Name">{item.Property_Name}</h3>
                      <h5 className="card-title Cardtitle" >{item.Property_Title}</h5>
                      <p className="card-text details" style={{ marginTop: '-5px', minHeight: '10px', overflow: 'hidden', color:"#4e5158" }}>
                        {item.Community}, {item.Unit_Type}.
                      </p>
                     <div className="cards_new_alter">

                  <div style={{display:"flex",alignItems:"center",gap:3}}>
                  <LazyLoadImage className="cards_new_icon"  src="/images/bedromm.png"  alt="bedroom" />
                  <span className="font_change_cards" style={{ color:"#4e5158"}}>{item.No_of_Rooms}</span>
                  <span id="" style={{color:"#ddd"}}>|</span>
                  </div>

                  <div  style={{display:"flex",alignItems:"center",gap:3}}>
                  <LazyLoadImage className="cards_new_icon" src="/images/bathromm.png" alt="bathroom" />
                  <span className="font_change_cards" style={{ color:"#4e5158"}}>{item.No_of_Bathroom}</span>
                  <span id="" style={{color:"#ddd"}}>|</span>
                  </div>

                  <div style={{display:"flex",alignItems:"center", gap:3}}>
                  <LazyLoadImage className="cards_new_icon" src="/images/Sizee.png" alt="bedroom" />
				  		<span className="font_change_cards" style={{ color:"#4e5158"}}>{item.Unit_Builtup_Area}</span>
                  <span className="text-lowercase" style={{color:"#4e5158"}}>{item.unit_measure}</span>
                    </div>

                </div>
                      <p className="price-details">AED <strong className="price-all">{formatNumberWithCommas(item.Price)}</strong></p>
                    </div>
					</Link>
                  </div>
                </div>
              );
            })
        )

  function getSelectValue(e){
   
   setDropvalue(e.target.value);
  }


  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [data, setdata] = useState([])


  return (
    <>
	       <Helmet>
                <title>Properties for Buy | Real Estate Company in Dubai </title>
                <meta name="description" content={ 'Explore a world of potential in the growing real estate market of Dubai. With the help of our thorough listings and knowledgeable advice, you can easily find your ideal real estate investment. Find the ideal house or investment opportunity that matches your needs and budget, from elegant villas to luxury apartments. '|| 'Default Description'} />
            
            </Helmet>
	      <div style={{height:"82px"}}></div>
      <div className="outer">
        <div id="outer-main">
          <div className='search-main'>
            <div className="SearchBar">
              <div style={{width:"95%", position:"relative"}}>
                <LazyLoadImage src="/images/search.png" className='search-icon'  alt="" />
                <input className='search' type="text" placeholder='Search...' value={searchTerm} onChange={handleSearchChange}/>
				{addressData && (
        <div className="sale-page-dropdown" style={{position:"absolute", zIndex:"1", boxShadow: "rgb(204, 204, 204) 0px 0px 10px 0px" }}>
          {/* Dropdown content goes here */}
          <ul className="dropdown-search-engine-list" style={{background:"#fff",maxHeight:"200px",overflowY:"scroll"}}>
          {addressData.map((item, index) => (
              <div
                key={index}
                style={{ marginLeft: "15px", cursor: "pointer",marginTop:"5px" }}
                onClick={() => handleItemClick(`${item.Property_Name}, ${item.Emirate}, ${item.Community}`)}
              >
                {item.Property_Name}, {item.Emirate}, {item.Community}
              </div>
            ))}
          
          </ul>
        </div>
      )}
				
              </div>
			  
			  
              <div className='main-btn'>
                <button id="btn-main" className='button-alter' onClick={funmini}>Filters
                </button>
              </div>
            </div>
          </div>

          <div id="allBtn" className='all-btns new-class'>
            <select className="select-input-field-change filter_file" onChange={getSelectValue}>
              <option value="Show All">Show All</option>
			  <option value="Apartment">Apartment</option>
			  <option value="Villa">Villa</option>
			  <option value="Townhouse">Townhouse</option>
              <option value="Penthouse">Penthouse</option>
              <option value="Duplex">Duplex</option>
              <option value="Plot">Plot</option>
              <option value="Land">Land</option>
            </select>
            <select name="" id="" className="select-input-field-change filter_file" onChange={handleMinPriceSelect}>

              <option value="Show All">Min Price</option>
              <option value="Show All">Show All</option>
            {priceList.map((item,index)=>{
              return(
                <option value={item}>AED {formatNumberWithCommas(item)}</option>
              )
            })}
            </select>
            <select name="" id="" className="select-input-field-change filter_file" onChange={handleMaxPriceSelect} >

              <option value="Show All">Max Price</option>
              <option value="Show All">Show All</option>
            {priceList.map((item,index)=>{
              return(
                <option value={item}>AED {formatNumberWithCommas(item)}</option>
              )
            })}
            </select>
            <select className="select-input-field-change filter_file" onChange={handleDropdownItemClick} name="" id="" >
              <option value="Show All">Bedrooms</option>
              <option value="Show All">Show All</option>
              <option value="1">1 Bedroom</option>
              <option value="2">2 Bedroom</option>
              <option value="3">3 Bedroom</option>
              <option value="4">4 Bedroom</option>
              <option value="5">5 Bedroom</option>
              <option onClick={handleSixPlusBed} value="6">6+ Bedroom</option>
            </select>



          </div>


        </div>

      </div>
      <div className="mt-5" id="salemaindiv">
        {data.map((item, index) => {
          return (
            <>
              <h3 id="pro-heading">{item.sec1heading} ({datalength})</h3>
            </>
          )
        })}


        {loading === true ? (

          <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", minHeight: "100vh" }}>
            <div >
              <div class="spinner-grow text-warning" role="status" style={{ marginRight: "10px", width: "2rem", height: "2rem" }}>
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status" style={{ marginRight: "10px", width: "2rem", height: "2rem" }}>
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status" style={{ width: "2rem", height: "2rem" }}>
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : jsonData.length >= 1 ? (
          <>

            <div className="row mb-10" id="drop-pagination">
              <div className="result-info col">
                Showing {currentPage * ITEMS_PER_PAGE + 1} -{" "}
                {Math.min((currentPage + 1) * ITEMS_PER_PAGE, datalength)}{" "}
                of {" "} {datalength} result
              </div>



            </div>

            <div >

              {loading ? (
                <div style={{textAlign:"center", margin:"auto"}}>
                  <div class="spinner-grow text-warning" role="status" style={{ marginRight: "10px", width: "2rem", height: "2rem" }}>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status" style={{ marginRight: "10px", width: "2rem", height: "2rem" }}>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status" style={{ width: "2rem", height: "2rem" }}>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) :
                displayedItems.length ? (
                  <div className="">
                    <div
                      className="row change-margin"
                      style={{margin:"auto"}}
                    >
                      {displayedItems}

                    </div>
                  </div>
                ) :
                  <div style={{ alignItems: "center", textAlign: "center" }}><h3 >No result found!</h3></div>
              }

            </div>



        
  <div className="pagination-container">
  <ReactPaginate
  previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
  nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
  breakLabel={"..."}
  pageCount={pageCount}
  marginPagesDisplayed={1} // Display 1 page before and after current page
  pageRangeDisplayed={1} // Display only the current page
  onPageChange={handlePageClick}
  containerClassName={"pagination"}
  activeClassName={"active"}
  previousClassName={"previous-button"}
  nextClassName={"next-button"}
/>
</div>

          </>
        ) : (
          <div>
            <h1 className="text-center">No Such Search Found</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default SaleDubaipro;