import React, { useEffect, useState, Suspense } from "react";
//import RightSideComponent from "./Right";
const RightSideComponent = React.lazy(() => import('./Right'));
import { Helmet } from 'react-helmet';
//import LeftComponent from "./Left";
const LeftComponent = React.lazy(() => import('./Left'));
import WhatsappChat from "../Notification/Whatapp";
import { useNavigate } from "react-router";
import axios from "axios";
import Gallery from "react-image-gallery";
import Modal from "react-bootstrap/Modal";
import '../../styles/internal/internal.css'
import '../../styles/main/sale.css'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CarouselComponents from "../CarouselComponents";



const SingleProperty = ({buyRentId,pageUrl}) => {
	let id;
	
	if(buyRentId){
		id=buyRentId;
	}else{
		id = localStorage.getItem('propertyid');
	}
		
	
	 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	
    const navigate = useNavigate()
const [propertyType,setPropertyType]=useState('');
	const [isLoading, setIsLoading] = useState(true);
const [propertyname,setPropertyname]=useState('');
       const [singledata, setdata] = useState([]);
    const [images1, setimages1] = useState([]);
    const [data,setData] = useState([]);
	
	



    useEffect(() => {
  axios
    .get(`/singleproperty1/${id}`)
    .then((res) => {
      if (res.data && res.data.length > 0) {
        setPropertyType(res.data[0].Ad_Type);
        setPropertyname(res.data[0].Property_Title);
        const data = JSON.parse(res.data[0].Imagelink);
        setimages1(data);
      } else {
        console.log("No data found in response");
      }
    })
    .catch((error) => {
      if (error.response) {
        // Backend returned an error status
        if (error.response.status === 404) {
          console.error("Error: ", error.response.data.msg); // Log the message from the backend
        } else {
          console.error("Error fetching data from the server:", error.response.data.msg || error.message);
        }
      } else {
        // Error in setting up the request
        console.error("Error: ", error.message);
      }
    });
}, [id]); // Include 'id' in the dependency array to re-fetch data when it changes

 
    //  function Singleproperty(e) {
    //    const id = e.target.id;
    //    const propertyName = e.target.getAttribute('data-propertyname');
    //    const currentPropertyName = localStorage.getItem('propertyName');
    
    //    if (propertyName !== currentPropertyName) {
    //        localStorage.setItem('propertyid', id);
    //        localStorage.setItem('propertyName', propertyName);
    //        window.location.href = '/singleproperty'; // Redirect to the same page
    //    } else {
    //        localStorage.setItem('propertyid', id);
    //        window.scrollTo(0, 0); // Scroll to the top of the page
    //    }
   // }
    
    useEffect(() => {
    let api;
    if (propertyType === "Sale") {
      api = "/allprosale";
    } else {
      api = "/allprorent";
    }

    axios.get(api)
      .then((res) => {
		const rsponseData = res.data;
        const shuffledData = shuffleArray(rsponseData);
        setData(shuffledData);
        //setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data from the server:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [propertyType]);
	
	
	  const shuffleArray = (rsponseData) => {
    //console.log(rsponseData);
    const shuffled = [...rsponseData];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, 4); // Return only the first three items after shuffling
  };
    
    
    const handleCardClick = (id) => {
        localStorage.setItem('propertyid', id);
		navigate(`/${id}`);
       // window.location.reload(); // Reload the current page
		
    };

   
    const [currentimg,setCurrentimg] = useState();
    const [lastIndex,setLastIndex] = useState();
    
    const newFun = (ind)=>{
        let b = document.getElementById(ind);
        setCurrentimg(ind);
      
        setLastIndex(images1.length-1)
        
        let c = document.getElementById("upBox")
        c.classList.add("hkfhkah")
        c.classList.remove("notVisible")
        const p = document.getElementById("jhyu354hu")
        let imgSource = b.getAttribute('src')
        let q = document.getElementById("gfy6569yueyu")
        if(q === null){
            let image = document.createElement("img");
            image.src = imgSource;
            image.alt = "Error loading image";
            image.id = "gfy6569yueyu";
            image.className = "main-image1"
    p.appendChild(image);
        }else{
            q.setAttribute('src',`${imgSource}`)
        }
        
    }
    let Remove = ()=>{
        let c = document.getElementById("upBox")
        c.classList.toggle("hkfhkah")
        c.classList.add("notVisible")
        setCurrentimg(0);
    }
	
	function handleClick(event) {
        // Check if the clicked element is not an image
        if (event.target.tagName !== 'IMG') {
            Remove(); // Run the Remove function
        }
    }
    useEffect(() => {
    
    }, [currentimg, lastIndex]);

let previous = ()=>{
       
            if(currentimg === 0){
                let d = document.getElementById(currentimg);
                setCurrentimg(prevState =>lastIndex);
                let imgSource = d.getAttribute('src');
                let u = document.getElementById("gfy6569yueyu");
                u.setAttribute('src',`${imgSource}`);
            }else{
                setCurrentimg(prevState =>currentimg-1)
               
                let d = document.getElementById(currentimg)
                            let imgSource = d.getAttribute('src')
                            let u = document.getElementById("gfy6569yueyu")
                u.setAttribute('src',`${imgSource}`)
            }
          
    }
      let next = () => {
        if (currentimg === lastIndex) {
            setCurrentimg(0);
        } else {
            setCurrentimg(prevState => currentimg + 1);
        }
    
        let d = document.getElementById(currentimg);
        if (d) {
            let imgSource = d.getAttribute('src');
            let u = document.getElementById("gfy6569yueyu");
            if (u) {
                u.setAttribute('src', imgSource);
            } else {
                console.error("Element with id 'gfy6569yueyu' not found.");
            }
        } else {
            console.error(`Element with id ${currentimg} not found.`);
        }
    }
	  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
	  
	  
	  useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 39) { // Right arrow key
                next();
            }
        };
        
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [next]);



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 37) { // Left arrow key
                previous();
            }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [previous]);
	  
	  
	  

let first = images1[0];
let second = images1[1];
let third = images1[2];
let fourth = images1[3];
let fifth = images1[4];


    return (
        <>
		      <Helmet>
                <title>{pageUrl}</title>
               
            
            </Helmet>
	

    <div id="upBox" className='notVisible' onClick={handleClick}>
    <div className='allBtnsss' >

        <LazyLoadImage src="/images/Cross.png" alt="Cross logo"  id="cross" onClick={Remove} className="crossx" />
        
    </div>
        <div className='keyuqui'>
        <div className='tpiuw'>
        <LazyLoadImage src="/images/Left.png" alt="Left logo" onClick={previous} className='tmjiuw'/>
        </div>
        <div id="jhyu354hu">

        </div>
        <div className='tdy98afy'>
        <LazyLoadImage  src="/images/Right.png" alt="Right logo" onClick={next} className='tkafy' />
        </div>
        </div>

    </div>

        

            <div>
           <div style={{ width: "87%", margin: "auto" }}>

           <div className='row' style={{padding:"0px"}}>
    {images1.map((image, index) => (
        index == 4 ? 
        <div className="Visible_div">
        <div className="newfjhjdhk">
        <LazyLoadImage style={{width:"100%"}} src={second} alt="one" id="0" onClick={()=> newFun(0)} className={`kaghuyg`}/>
        </div>
        <div className="ytttythk">
        <LazyLoadImage src={first} alt="two" id="1" onClick={()=> newFun(1)} className={"jvhsxcdc main-jg"} />
        <LazyLoadImage src={third} alt="three" id="2" onClick={()=> newFun(2)} className={"gyufwt main-jg"} />
        <LazyLoadImage src={fourth} alt="four" id="3" onClick={()=> newFun(3)} className={"gyuggf main-jg"} />
        <LazyLoadImage src={fifth} alt="five" id="4" onClick={()=> newFun(4)} className={"yjgewgs main-jg"} />
        </div>
        </div>
           
        : index >= 5?
        <LazyLoadImage key={index} src={image} alt={index} id={index} onClick={() => newFun(index)} className='noyt'/>
        : null
    ))}
    
</div>


 <CarouselComponents images={images1} />
<div className="row internal-comp" style={{padding:"0px"}}>

<div className="col-12 col-sm-12 col-md-12	col-lg-8 col-xl-8" style={{padding:"0px"}} >
   
		
		<Suspense fallback={<div>Loading...</div>}>
   <LeftComponent buyRentId={buyRentId} pageUrl={pageUrl}/>
</Suspense>
</div>

<div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 miu678">
    
		
		<Suspense fallback={<div>Loading...</div>}>
   <RightSideComponent buyRentId={buyRentId}/>
</Suspense>
</div>
</div>
           </div>
               
          

                <WhatsappChat />

            </div>
    <div className="row" style={{width:"87%",margin:"auto", marginBottom:"30px"}}>
		<h3 style={{padding:"0px 12px", color: "rgb(80, 77, 79)", marginBottom:"25px" }}>View More Properties</h3>
        {isLoading ? (
        <p>Loading...</p> // Replace with a spinner or loading component
      ) : (
        data.map((item, index) => {
          if (item.Property_Title === propertyname) {
            return null; // Skip rendering this card
          }

          const im = JSON.parse(item.Imagelink);
          const items = im[1];

          return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4" key={item.PropertyID}>
              <div className="card" id="main-div-card" style={{ borderRadius: "0px" }}>
			
                <div style={{ overflow: "hidden" }} onClick={() => handleCardClick(item.pagelink)}>
                  <div className="h-a-v-e-m-o-r-e">
                    <LazyLoadImage className="card-img-top" src={items} alt="Card cap" style={{ height: '210px', cursor: 'pointer', borderRadius: "0px" }} />
                    <div className="card-body" style={{ padding: '20px' }}>
                      <h5 className="card-title Cardtitle">{item.Property_Title}</h5>
                      <p className="card-text details" style={{ marginTop: '-5px', minHeight: '10px', overflow: 'hidden', color: "#697a8a" }}>
                        {item.Community}, {item.Unit_Type}.
                      </p>
                      <div className="additional-details mt-4" style={{ marginBottom: '30px', width: "100%", display: "flex" }}>
                        <div className="bed details mt-2" style={{ width: "25%", display: "flex" }}>
                          <LazyLoadImage src="/images/bedromm.png" style={{ width: "28px", objectFit: "contain" }} alt="bedroom" />
                          <span style={{ margin: "5px", color: "#4e5158" }}>{item.No_of_Rooms}</span>
                          <span id="spacing" style={{ marginTop: "5px" }}>|</span>
                        </div>
                        <div className="bathtub details mt-2" style={{ width: "25%", display: "flex" }}>
                          <LazyLoadImage src="/images/bathromm.png" style={{ width: "28px", objectFit: "contain" }} alt="bathroom" />
                          <span style={{ margin: "5px", color: "#4e5158" }}>{item.No_of_Bathroom}</span>
                          <span id="spacing" style={{ marginTop: "5px" }}>|</span>
                        </div>
                        <div className="squre-ft details mt-2" style={{ width: "50%", display: "flex" }}>
                          <LazyLoadImage src="/images/Sizee.png" style={{ width: "28px", objectFit: "contain" }} alt="bedroom" />
                          <span style={{ color: "#4e5158" }}>{item.Unit_Builtup_Area}</span>
                          <span className="text-lowercase" style={{ margin: "5px", color: "#4e5158" }}>{item.unit_measure}</span>
                        </div>
                      </div>
                      <p className="price-details">AED <strong className="price-all">{formatNumberWithCommas(item.Price)}</strong></p>
                    </div>
                  </div>
                </div>
																		  
              </div>
            </div>
          );
        })
      )
}



            </div>
        </>
    );
};

export default SingleProperty;